.main-title{
    border-radius: 5px;
    width: 40%;
    //margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 10px 10px;
    animation: 0.4s cubic-bezier(.25,1.1,.5,1.2) 0.6s;
    animation-fill-mode: both;
    background-image: linear-gradient(45deg,#ff9800,#ff5722);
    font-size: x-large;
    font-weight: 600;
    box-shadow: 1px 2px 7px #856464;
    color: #fff;
    & a{
    	color: #fff;
    }
}
.load-more-posts-button-w span{
    display: inline-block;
    padding: 10px 25px 10px 20px;
    border: none;
    color: #111;
    -webkit-box-shadow: 0px 7px 14px 0px rgba(151,160,204,0.12);
    box-shadow: 0px 7px 14px 0px rgba(151,160,204,0.12);
    background-color: #fff;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.main-footer.with-social .footer-social-w{
    width: 100%;
    text-align: center;
    padding: 15px;
}
.post-meta{
    padding: 25px 50px;
}
ul.post-tags{
    list-style: none;
    & > li{
        margin: 2px;
        & > a{
            display: inline;
            padding: 3px 7px;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: 1px;
            font-weight: 700;
            background-color: $orange;
            color: #fff;
            border: 1px solid $orange;
            border-radius: 6px;
            text-transform: uppercase;
            &:hover{
                border-color: #0e3aa0;
                background-color: #86a7f4;
                text-decoration: none;
            }
        }
    }
}
/*.menu-position-right .menu-block{
    width: 350px;
}
.menu-position-right .main-content-w{
    margin-right: 350px;
}*/
@media screen and (max-width: 1100px) {
    .featured-carousel-post .post-content-body .post-title{
        font-size: unset;
    }
    .latest-posts-w a span{
        font-size: small;
    }
}
.post-body{
    & h2 {
        font-size: 2.2rem;
    }
    & p{
    font-size: 1.6rem;
    }
}
.menu-position-left .menu-block, .menu-position-right .menu-block{
    padding: 10px;
    & span.tptn_title{
        font-size: 1.6rem;
    }
    & ul > li{
        font-size: 1.6rem !important;
    }
}
.mw-100{
    max-width: 100%;
}
.mb-2{
    margin-bottom: 2rem;
}
.advert{
    animation-name: keyframes-shadow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes keyframes-shadow {
  from {
    box-shadow: 1px 2px 7px #fff;
    }
  to {
    box-shadow: 1px 2px 7px;
    }
}
.cursor-pointer{
    cursor: pointer;
}
ul.post-tags{
    display: flex;
    flex-wrap: wrap;
}